import React from "react";
import styles from "./pages.module.scss";

import Banner from "../components/banner";
import GIHeader from "../components/getInvolved_header";
import GIDonate from "../components/getInvolved_donate";
import GIVolunteer from "../components/getInvolved_volunteer";

export default () => (
  <div className={styles.Page}>
    <Banner page="get-involved" />
    <div className={styles.content_container}>
      <GIHeader />
      <GIDonate />
      <GIVolunteer />
    </div>
  </div>
);
