import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./getInvolved_volunteer.module.scss";

//only need this in dev
// const GATEWAY_URL = process.env.GATEWAY_URL;
const required = "This field is required";

const GIVolunteer = () => {
  const [options] = useState([
    "post a yard sign at my house",
    "sign wave",
    "canvas our neighborhood",
    "make phone calls",
    "send postcards to my friends and family in our community",
    "host a gathering of friends and family to meet Linda",
    "anything else?",
  ]);

  //react-hook-form start
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    reset,
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    let formData = Object.assign({}, { name: data.name, email: data.email });

    delete data.email;
    delete data.name;

    let volunteerActivities = Object.keys(data).reduce((acc, curr) => {
      if (!data[curr] || curr === "anything else?") {
        return acc;
      }

      if (curr === "whatElse") {
        acc.push(data[curr]);
        return acc;
      }

      acc.push(curr);
      return acc;
    }, []);

    formData["volunteerActivities"] = volunteerActivities;

    try {
      // change to GATEWAY_URL in dev
      await fetch("/api/email", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      reset();
      setSubmitted(true);
    } catch (err) {
      //handle server errors
      setError(
        "submit",
        "submitError",
        `Oops, something went wrong! ${err.message}`
      );
    }
  };

  const showSubmitError = (msg) => (
    <p className={styles.message_error}>{msg}</p>
  );

  const [submitted, setSubmitted] = useState(false);

  const showThankYou = (
    <div className={styles.thankYou_message}>
      <div>
        <h3>MESSAGE SENT</h3>
        <p>Thank you for your message!</p>
        <button onClick={() => setSubmitted(false)}>CLOSE</button>
      </div>
    </div>
  );

  //react-hook-form end

  return (
    <div className={styles.GIVolunteer}>
      {submitted ? showThankYou : null}

      <h2>VOLUNTEER</h2>
      <p>Willing to share your time? Tell us how you can help!</p>

      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form_left}>
          <label>
            Name
            <input
              type="text"
              name="name"
              placeholder="Full name"
              ref={register({ required })}
              disabled={isSubmitting}
            />
            {errors.name && (
              <div className={styles.message_error}>{errors.name.message}</div>
            )}
          </label>
          <label>
            Email
            <input
              type="text"
              name="email"
              placeholder="email@email.com"
              ref={register({ required })}
              disabled={isSubmitting}
            />
            {errors.email && (
              <div className={styles.message_error}>{errors.email.message}</div>
            )}
          </label>
          <input
            type="submit"
            value="SUBMIT"
            className={styles.submit_button}
            disabled={isSubmitting}
          />
          {errors && errors.submit && showSubmitError(errors.submit.message)}
        </div>
        <div className={styles.form_right}>
          <p>
            <i>What can you help with?</i>
          </p>
          {options.map((option, index) => {
            if (option !== "anything else?") {
              return (
                <label key={`volunteer_${index}`}>
                  <input
                    type="checkbox"
                    name={option}
                    id={option}
                    disabled={isSubmitting}
                    ref={register}
                  />
                  {option}
                </label>
              );
            } else {
              return (
                <label key={`volunteer_${index}`}>
                  <input
                    type="checkbox"
                    name={option}
                    id={option}
                    ref={register}
                  />
                  {option}
                  <input
                    type="text"
                    className={styles.anythingElse_input}
                    id="whatElse"
                    name="whatElse"
                    placeholder="tell use how you can help..."
                    ref={register}
                  />
                </label>
              );
            }
          })}
        </div>
      </form>
    </div>
  );
};

export default GIVolunteer;
