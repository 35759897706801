import React from "react";
import styles from "./getInvolved_donate.module.scss";

const GIDonate = () => {
  return (
    <div className={styles.GIDonate}>
      <h2>DONATE</h2>
      <p>
        Thank you for your support! You know have two ways to make your
        contribution:
      </p>

      <div className={styles.donation_options_div}>
        <div className={styles.online_option}>
          <h3>ONLINE</h3>
          <p>
            <i>Click the button below to make a contribution online.</i>
          </p>
          <a
            className={styles.contribute_button}
            href="https://secure.actblue.com/donate/friends-of-linda-ichiyama"
            target="_blank"
            rel="noopener noreferrer"
          >
            DONATE ONLINE
          </a>
        </div>
        <div className={styles.mail_option}>
          <h3>BY MAIL</h3>
          <p>
            <i>Checks can be made out to:</i>
          </p>
          <address>
            <p>Friends of Linda Ichiyama</p>
            <p>P.O. Box 1707</p>
            <p>AIEA, HI 96701</p>
          </address>
        </div>
      </div>
    </div>
  );
};

export default GIDonate;
