import React from "react";
import styles from "./getInvolved_header.module.scss";

const GIHeader = (props) => {
  return (
    <div className={styles.Header}>
      <h3>TOGETHER WE CAN BUILD A BETTER, STRONGER HAWAII</h3>
      <p>and there are a number of ways to help.</p>
    </div>
  );
};

export default GIHeader;
